import React from "react"
import "../styles/PostTemplate.scss"
//import { PostMdl } from "/src/cchelper"
import Post from "../components/Post"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function PostTemplate({ pageContext, location }) {
  var { post, missingImg, menuData } = pageContext
  //var post = new PostMdl(pageContext.post);

  const art = (
    <Layout menuData={menuData} location={location}>
      <Seo title={post.title} />
      <Post post={post} missingImg={missingImg}></Post>
    </Layout>
  )
  return art
}
