import React from "react"
import "../styles/Post.scss"
import { PostMdl } from "/src/cchelper"
import MarkdownContent from "./MarkdownContent"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { has } from "lodash"
import TagList from "./TagList"
import { Helmet } from "react-helmet"

export default function Post(props) {
  var { post, missingImg } = props
  post = new PostMdl(post)
  var img = getImage(post?.image?.image)
  // var img = has(post, "image.image.childImageSharp.fluid")
  //   ? post.image.image.childImageSharp.gatsbyImageData
  //   : missingImg.fluid
  var imgmediumurl = post?.image?.formats?.medium?.url ?? post?.image?.url

  const art = (
    <article className="post">
      <Helmet>
        {/* <meta property="og:url"                content="http://www.nytimes.com/2015/02/19/arts/international/when-great-minds-dont-think-alike.html" /> */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.excerpt} />
        <meta property="og:image" itemprop="image" content={imgmediumurl} />
      </Helmet>

      <h3 className="title">{post.title}</h3>

      <div className="image">
        <GatsbyImage image={img} objectFit="cover" alt="" />
        {/* <img src={p.image.url} alt="post images" /> */}
      </div>

      {/*<div className="content">{ post.content }</div>*/}
      <MarkdownContent
        className="content"
        content={post.content}
      ></MarkdownContent>

      <TagList className="tags" prefix={"tag"} tags={post.tags}>
        Tags:{" "}
      </TagList>

      <div className="date">{post.date}</div>
      {/* <div className="tags">Tags: { post.getTagList() }</div> */}
    </article>
  )

  return art
}
