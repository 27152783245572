import React from "react"
import {kebabCase} from "lodash"
import { Link } from "gatsby"

export default function TagList(props){
	var {tags='', prefix='', children } = props;
	var numtags = tags.length; 
	var tagList = tags.map((tag, key)=>{
		var slug = kebabCase(tag.name)
		var lbl = numtags ===key+1 ? tag.name : tag.name + ', ';
		var to = prefix+'/'+slug;
		return (
			<Link className='tag-item' 
					to={to} key={key}>
				{lbl}
			</Link>
		)
	})
   	const art = (
	   	<div className={props.className}>
	   		{tagList.length>0?children:''} {tagList}
		</div>
	);
    return art;
}
